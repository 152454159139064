<template>


    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                        <div class="col-12 mt-2">
                            <h2 class="content-header-title float-start mb-0">POST JOB AD</h2>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">start new job ad</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-body">
                <!-- full job description -->
                    <div class="row">
                        <div class="col-md-9">

                            <create-job-description :isJobAd="true" :isUpdateJobAd="false">
                            </create-job-description>

                        </div>
                    </div>
                <!-- full job description -->

            </div>
        </div>
    </div>
    <!-- END: Content-->


</template>

<script>
// import the core functions from vuex
import { useStore } from 'vuex';

import CreateJobDescription from '@/components/forms/recruitment/CreateJobDescription.vue';

export default{
    components:{
        CreateJobDescription
    },
    setup(){
        // get the global vuex store values
        const store = useStore();

        // use the dispatch function to call an action that calls a mutation
        store.dispatch("globals/executeSetNavMenu",true);

    }
}


</script>

<style scoped>

</style>
